import plan from '../assets/icon-v2-galleryArch.png'
import gallery from '../assets/icon-v2-galleryImage.png'
import video from '../assets/icon-v2-galleryMovie.png'
import material from '../assets/icon-v2-materialChanger.png'
import level from '../assets/icon-v2-map.png'
import capture from '../assets/icon-v2-capture.png'
import {ReactComponent as HelpIcon} from '../assets/question3.svg'


type MenuItemProps = {
  itemName: string
  setImageOpen?: React.Dispatch<React.SetStateAction<boolean>>
  imageOpen?: boolean
  setVideoOpen?: React.Dispatch<React.SetStateAction<boolean>>
  setPlansOpen?: React.Dispatch<React.SetStateAction<boolean>>
  setBurgerOpen?: React.Dispatch<React.SetStateAction<boolean>>
  setScreenShotOpen?: React.Dispatch<React.SetStateAction<boolean>>
}
const MenuItem = (menuItemProps: MenuItemProps) => {
  const { itemName, setImageOpen, imageOpen, setPlansOpen, setVideoOpen, setBurgerOpen, setScreenShotOpen } = menuItemProps

  const galleryOnClick = () => {
    setPlansOpen!(false)
    setVideoOpen!(false)
    setImageOpen!(!imageOpen) 
  }

  const plansOnClick = () => {
    setImageOpen!(false)
    setVideoOpen!(false)
    setPlansOpen!(true)
  }

  const videoOnClick = () => {
    setImageOpen!(false)
    setPlansOpen!(false)
    setVideoOpen!(true)
  }

  const screenShotOnClick = () => {
    setImageOpen!(false)
    setPlansOpen!(false)
    setVideoOpen!(false)
    setScreenShotOpen!(true)
  }

  if (itemName === 'plans') {
    return (
      <div className='menuItem' onClick={plansOnClick}>
        <img src={plan} alt='Plans menu item' height='40px'/>
      </div>
    )
  }

  else if (itemName === 'gallery') {
    return (
      <div className='menuItem' id='gallery' onClick={galleryOnClick}>
        <img src={gallery} alt='Gallery menu item' height='40px'/>
      </div>
    )
  }

  else if (itemName === 'material') {
    return (
      <div className='menuItem clickThrough' id='material'>
        <img src={material} alt='Material menu item' height='40px'/>
      </div>
    )
  }

  else if (itemName === 'video') {
    return (
      <div className='menuItem' onClick={videoOnClick}>
        <img src={video} alt='Video menu item' height='40px'/>
      </div>
    )
  }

  else if (itemName === 'capture') {
    return (
      <div className='menuItem' onClick={screenShotOnClick}>
        <img src={capture} alt='Capture menu item' height='40px'/>
      </div>
    )
  }

  else if (itemName === 'level') {
    return (
      <div className='menuItem'>
        <img src={level} alt='Level menu item' height='40px'/>
      </div>
    )
  }

  else if (itemName === 'help') {
    return (
      <div className='menuItem'>
        <HelpIcon height='40px' width='40px'/>
      </div>
    )
  }

  else if (itemName === 'burger') {
    return (
      <div className='menuItem' onClick={() => setBurgerOpen!(true)}>
        <img src={level} alt='Burger menu item' height='40px'/>
      </div>
    )
  }

  else return <> </>
}

export default MenuItem
