import html2canvas from 'html2canvas'
import { useEffect, useState } from 'react'

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import Modal from './Modal'

type ScreenShotProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const ScreenShot = ({setOpen}: ScreenShotProps) => {
  const [screenshot, setScreenshot] = useState<HTMLCanvasElement | null>(null)
  const [image, setImage] = useState('')

  // useEffect(() => {
  //   html2canvas(document.body)
  //     .then(canvas => document.body.appendChild(canvas))
  // }, [])

  useEffect(() => {
    htmlToImage.toPng(document.getElementById('canvasZone')!)
      .then(dataUrl => {
        const img = new Image()
        img.src = dataUrl
        setImage(dataUrl)
      })
  }, [])

  return (
    <Modal setOpen={setOpen}>
      <img src={image} style={{maxWidth: '100%', maxHeight: '100%', margin: '3rem'}}/>
    </Modal>
  )
}

export default ScreenShot