import { SRLWrapper } from 'simple-react-lightbox'

import NewGallery from "./Gallery"
import NewModal from "./Modal"

type PlanGalleryProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const PlanGallery = ({setOpen}: PlanGalleryProps) => {

  const importAllPaths = (r: __WebpackModuleApi.RequireContext) => {
    return r.keys()
  }

  const paths = importAllPaths(require.context('../assets/plans', true))

  return (
    <NewModal setOpen={setOpen}>
      <SRLWrapper>
        <NewGallery subPath='plans' paths={paths}/>
      </SRLWrapper>
    </NewModal>
  )
}

export default PlanGallery