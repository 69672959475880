
import './ImageGallery.css'
import ImageThumb from './ImageThumb'

type NewGalleryProps = {
  subPath: string
  paths: string[]
}
const NewGallery = ({subPath, paths}: NewGalleryProps) => {

  return (
    <div className='galleryGrid'>
      {paths.map(path => {
      return <ImageThumb subPath={subPath} path={path.substring(1)}/>
      })}
    </div>
  )
}

export default NewGallery