import './Menu.css'
import { ReactComponent as CloseIcon } from '../assets/close.svg'

type NewModalProps = {
  open?: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  children?: React.ReactNode
}
const NewModal = ({open, setOpen, children}: NewModalProps) => {

  return (
    <div className='blurOverlay' onClick={() => setOpen(false)} >
      <div className='modalPopup'>
        <CloseIcon className='closeIcon' onClick={() => setOpen(false)}/>
        {children}
      </div>
    </div>
  )
}

export default NewModal