import { useState, useEffect } from 'react'
import './Menu.css'
import MenuItem from './MenuItem'
import useMediaQuery from '../hooks/useMediaQuery'


type MenuProps = {
  setImageOpen: React.Dispatch<React.SetStateAction<boolean>>
  imageOpen: boolean
  setVideoOpen: React.Dispatch<React.SetStateAction<boolean>>
  setPlansOpen: React.Dispatch<React.SetStateAction<boolean>>
  setScreenShotOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const Menu = ({ setImageOpen, setVideoOpen, imageOpen, setPlansOpen, setScreenShotOpen }: MenuProps) => {
  const isPhone = useMediaQuery('(max-width: 479px)')

  return (
    <>
      <div className='menuBar'>
        <MenuItem itemName='plans' setPlansOpen={setPlansOpen} setImageOpen={setImageOpen} setVideoOpen={setVideoOpen} />
        <MenuItem itemName='gallery' setImageOpen={setImageOpen} imageOpen={imageOpen} setPlansOpen={setPlansOpen} setVideoOpen={setVideoOpen}/>
        <MenuItem itemName='video'  setVideoOpen={setVideoOpen} setImageOpen={setImageOpen} setPlansOpen={setPlansOpen} />
        <MenuItem itemName='material'/>
        <MenuItem itemName='level' setScreenShotOpen={setScreenShotOpen} setVideoOpen={setVideoOpen} setImageOpen={setImageOpen} setPlansOpen={setPlansOpen} />
        <MenuItem itemName='capture' setScreenShotOpen={setScreenShotOpen} setVideoOpen={setVideoOpen} setImageOpen={setImageOpen} setPlansOpen={setPlansOpen} />
        <MenuItem itemName='help'/>
      </div>
    </>
  )
}

export default Menu