import { useState } from 'react'
import { SRLWrapper } from 'simple-react-lightbox'
import Lightbox from 'react-image-lightbox'

import NewGallery from "./Gallery"
import NewModal from "./Modal"

type RenderGalleryProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const RenderGallery = ({setOpen}: RenderGalleryProps) => {
  const [lightboxOpen, setLightboxOpen]= useState(true) 

  const importAllPaths = (r: __WebpackModuleApi.RequireContext) => {
    return r.keys()
  }

  const paths = importAllPaths(require.context('../assets/renders', true))

  return (
    <NewModal setOpen={setOpen}>
      <SRLWrapper>
        <NewGallery subPath='renders' paths={paths}/>
      </SRLWrapper>
    </NewModal>
  )
}

export default RenderGallery

// {lightboxOpen && (
//   <Lightbox 
//     mainSrc={require('../assets/renders/BM_RenderCamera_08_BedroomA+Interior+Light_Scheme+Bedroom.jpg').default}
//     onCloseRequest={() => setLightboxOpen(false)}/>
// )}