import Modal from './Modal'

type VideoProps = {
  open?: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const Video = ({setOpen}: VideoProps) => {
  return (
    <Modal setOpen={setOpen}>
      <div style={{padding: '56.25% 0 0 0', position: 'relative', margin: '3rem'}}>
        <iframe 
          src="https://player.vimeo.com/video/645405395?h=abe112b8ee&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0" 
          frameBorder="0" 
          allow="autoplay; fullscreen; picture-in-picture" 
          allowFullScreen 
          className='widescreenLock'
          style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
          title="Lendlease - Ardency Kennedy Place - Unit 302">
        </iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </Modal>
  )
}

export default Video