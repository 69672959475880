import { useState, useEffect } from 'react'
import './App.css';
import 'react-image-lightbox/style.css'


import Menu from './components/Menu';
import PlanGallery from './components/PlanGallery';
import RenderGallery from './components/RenderGallery';
import Video from './components/Video'
import ScreenShot from 'components/ScreenShot';


function App() {
  const [rendersOpen, setRendersOpen] = useState(false)
  const [plansOpen, setPlansOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState (false)
  const [screenShotOpen, setScreenShotOpen] = useState(false)

  return (
    <div className="App">
      <Menu 
        setImageOpen={setRendersOpen} 
        imageOpen={rendersOpen} 
        setPlansOpen={setPlansOpen}
        setVideoOpen={setVideoOpen}
        setScreenShotOpen={setScreenShotOpen} 
      />
      { rendersOpen && <RenderGallery setOpen={setRendersOpen}/> }
      { plansOpen && <PlanGallery setOpen={setPlansOpen} /> }
      { videoOpen && <Video setOpen={setVideoOpen}/> }
      { screenShotOpen && <ScreenShot setOpen={setScreenShotOpen}/> }
    </div>
  );
}

export default App;
