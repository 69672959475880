type ImageThumbProps = {
  subPath: string
  path: string
}
const ImageThumb = ({ subPath, path }: ImageThumbProps) => {
  const formatRenderLabel = (fileName: string) => {
    return fileName.replace('/BM_RenderCamera_', '')
  }

  const label = path

  const formattedLabel = formatRenderLabel(label)

  return (
    <a href={require(`../assets/${subPath}${path}`).default}>
      <img src={require(`../assets/${subPath}${path}`).default} className='imageThumb' alt={path} />
      <p style={{color: 'initial', textDecoration: 'initial'}}>{formattedLabel}</p>
    </a>
  )
}

export default ImageThumb

